import React from "react";
import PropTypes from "prop-types";
import Container from "@material-ui/core/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: '60px',
        display: 'flex',
        flexDirection: 'column',
        '& .container': {
            display: 'flex',
            flexDirection: 'column',
            padding: '0 34px',
            '& ul': {
                padding: '0 20px',
            },
            '& li': {
                margin: 0
            }
        },
        '& .titre': {
            textAlign: 'center'
        },
        '& .sous-titre': {
            textAlign: 'left',
            marginTop: '30px',
        },
        '& .content': {
            marginTop: '30px',
            textAlign: 'justify',
        },
        '& .noMargin': {
            marginTop: 0,
        }
    },
}));

const StaticPageLayout = ({children}) => {
    const classes = useStyles();

    return (
        <Container className={classes.root}>
            {children}
        </Container>
    );
};

StaticPageLayout.propTypes = {
    children: PropTypes.node
};

StaticPageLayout.defaultProps = {
    children: null
};

export default StaticPageLayout;