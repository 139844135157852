import qs from "qs";
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import {cleanJwtToken, setJwtToken} from "../actions/authentication/localStorageAction";

const isTokenExpired = token => {
    const decoded = jwtDecode(token);
    const currentTime = new Date();
    const expiresDate = new Date(decoded.exp * 1000);

    return currentTime > expiresDate;
};

export const formatFetchParams = params => {
    const formatedParams = {};
    Object.keys(params).forEach(param => {
        if(Array.isArray(params[param])) {
            formatedParams[param] = params[param].filter(value => value !== null);
        } else if(params[param] !== null) {
            formatedParams[param] = params[param];
        }
    });

    return formatedParams;
}

export const refreshToken = () => {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('refresh_token');
        if(token) {
            const param = qs.stringify({"refresh_token": token});
            axios.post(`${process.env.API_URL}/token/refresh`, param)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        } else {
            reject({response: {status: 401}});
        }
    });
};

const fetch = ({
                   dispatch = null,
                   isPublic = false
               } = {}) => {
    const instance = axios.create({
        baseURL: process.env.API_URL,
        withCredentials: true
    });
    const token = localStorage.getItem('token');
    instance.interceptors.request.use((config) => {
        if(isPublic) return config;
        if(!token) {
            return Promise.reject({response: {status: 401}});
        }

        const nextConfig = {...config};
        nextConfig.headers.Authorization = `Bearer ${token}`;

        if(isTokenExpired(token)) {
            return refreshToken()
                .then((response) => {
                    const {token, refresh_token: refreshToken} = response.data;
                    setJwtToken(token, refreshToken);
                    nextConfig.headers.Authorization = `Bearer ${token}`;
                    return Promise.resolve(nextConfig);
                })
                .catch(error => {
                    cleanJwtToken();
                    return Promise.reject(error);
                });
        }

        return nextConfig;
    }, (error) => Promise.reject(error));

    instance.interceptors.response.use(response => response, (error) => {
        return Promise.reject(error)
    });

    return instance;
};

export const fakeFetch = (url, params = {}, data = null) => new Promise((resolve, reject) => {
    setTimeout(() => {
        resolve({data});
    }, 300);
});

export default fetch;
