import {ContainedSecondaryButtonBase, OutlinedButtonBase, SmallButton} from "./Button";

const ToggleButton = theme => ({
    root: {
        ...OutlinedButtonBase(theme),
        '&$selected': {
            ...ContainedSecondaryButtonBase(theme),
        },
        '&$sizeSmall': {
            textTransform: 'none',
            ...SmallButton(theme),
            ...SmallButton(theme)['&$outlined']
        },
        '& $label': {
            '& .MuiSvgIcon-fontSizeSmall': {
                marginRight: '5px',
            }
        }
    },
});

export default ToggleButton;
