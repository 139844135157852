import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Container from '@material-ui/core/Container';
import {useDispatch} from "react-redux";
import fetch from "../fetch";
import staticData from "../manager/staticData";
import {DISPLAY_ERROR_PAGE} from "../reducers/errorPageReducer";
import Loader from "../components/Loader";

const MainLayout = ({children}) => {
    const dispatch = useDispatch();
    const [fulfilled, setFulfilled] = useState(false);

    useEffect(() => {
        fetch({isPublic: true}).get('/static')
            .then(response => {
                staticData.initialized(response.data);
                setFulfilled(true);
            })
            .catch(() => {
                dispatch({type: DISPLAY_ERROR_PAGE, payload: {code: 500}});
            })
    }, [])


    if(!fulfilled) {
        return (
            <>
                <Container maxWidth={false} disableGutters={true}>
                    <Loader textKey="static" />
                </Container>
            </>
        );
    }

    return (
        <>
            <Container maxWidth={false} disableGutters={true}>
                {children}
            </Container>
        </>
    );
};

MainLayout.propTypes = {
    children: PropTypes.node
};

MainLayout.defaultProps = {
    children: null
};

export default MainLayout;