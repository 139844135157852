import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import MainLayout from "./MainLayout";
import {useIsStaticPage} from "../hooks/router/useRouter";
import StaticPageLayout from "./StaticPageLayout/StaticPageLayout";

const Layout = ({children}) => {
    const isStaticPage = useIsStaticPage();

    if(isStaticPage) {
        return (
            <StaticPageLayout>
                {children}
            </StaticPageLayout>
        );
    }

    return (
        <MainLayout>
            {children}
        </MainLayout>
    );
};

Layout.propTypes = {
    children: PropTypes.node
};

Layout.defaultProps = {
    children: null
};

export default Layout;