export const TAG_CATEGORY_DEFAULT = "default";
export const TAG_CATEGORY_ANNEE = "year";
export const TAG_CATEGORY_BDD = "base";
export const TAG_CATEGORY_SOIN = "care";
export const TAG_CATEGORY_MISC = "misc";

export const TAGS_ORDER = [
    TAG_CATEGORY_BDD => 0,
    TAG_CATEGORY_SOIN => 1,
    TAG_CATEGORY_ANNEE => 2,
    TAG_CATEGORY_MISC => 3,
    TAG_CATEGORY_DEFAULT => 4
];