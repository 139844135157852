export const QUERY_PARAM_TAGS   = 'tags';

export const MODE_PRIV = 'private';
export const MODE_PUB = 'public';

export const PARAM_BDD_SNDS = {
    key: 'snds',
    mode: MODE_PUB
}

export const PARAM_BDD_SNDSS = {
    key: 'sndsh',
    mode: MODE_PRIV
}
export const PARAMS_BDD = [
    PARAM_BDD_SNDS,
    PARAM_BDD_SNDSS
];

export const PARAM_DISPLAY_ROUE = 'roue';
export const PARAM_DISPLAY_TABLEAU = 'tableau';
export const PARAMS_DISPLAY = [
    PARAM_DISPLAY_ROUE,
    PARAM_DISPLAY_TABLEAU
];

export const ACTION_GPS_FETCH_PENDING   = 'ACTION_GPS_FETCH_PENDING';
export const ACTION_GPS_FETCH_FULFILLED = 'ACTION_GPS_FETCH_FULFILLED';
export const ACTION_GPS_FETCH_ERROR     = 'ACTION_GPS_FETCH_ERROR';
export const ACTION_UPDATE_FILTER       = 'ACTION_UPDATE_FILTER';


export const ACTION_EXPORT_FETCH_PENDING    = 'ACTION_EXPORT_FETCH_PENDING';
export const ACTION_EXPORT_FETCH_FULFILLED  = 'ACTION_EXPORT_FETCH_FULFILLED';
export const ACTION_EXPORT_FETCH_ERROR      = 'ACTION_EXPORT_FETCH_ERROR';