import React, {Suspense} from "react";
import {Switch, Route} from "react-router";
import routes from "./routes";
import RouteChild from "./routes/Route";

const Root = () => {
    return (
        <Suspense fallback={null}>
            <Switch>
                {routes.map(({name, path, component, isPublic, layout}, i) => (
                    <Route
                        key={i}
                        path={path}
                        exact
                    >
                        <RouteChild
                            name={name}
                            component={component}
                            isPublic={isPublic}
                        />
                    </Route>
                ))}
            </Switch>
        </Suspense>
    );
};

export default Root;