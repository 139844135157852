import theme from "../Material/theme/theme";
import staticData from "./staticData";
import {TAG_CATEGORY_SOIN} from "../constants/tags";

export const isNotFilter = (node, query, withCare = true) => {
    if (!query) return null;

    const {tags} = node;
    if (query.year.length > 0 && !tags.some(tag => query.year.includes(tag))) return 0;
    if (query.misc.length > 0 && !tags.some(tag => query.misc.includes(tag))) return 0;
    if (withCare && query.care.length > 0 && !tags.some(tag => query.care.includes(tag))) return 0;

    return 1;
}

export const getTagsCount = (root, tagsCount = {}, query) => {
    const {tags, children} = root;
    const cares = staticData.getTags(TAG_CATEGORY_SOIN).map((care) => care.id);
    if(children) {
        children.forEach(child => {
            tagsCount = getTagsCount(child, tagsCount, query);
        });
    }

    if (root.size === 0 && isNotFilter(root, query, false) === 0) {
        return tagsCount;
    }

    return tags.reduce((accu, id) => {
        if(!accu[id]) accu[id] = 0;
        if (isNotFilter(root, query, !cares.includes(id)))
        accu[id]++;
        return accu;
    }, tagsCount);
}

const getNodeColor = (node, lvl) => {
    const {parent_id: parentId, children, care_type: careType} = node;
    if(parentId === null) {
        return theme.palette.appComponents.defaultNode.root
    }
    if(lvl === 1) {
        return theme.palette.appComponents.defaultNode.level1
    }

    if(children.length === 0 && theme.palette.appComponents.buttonType[careType]) {
        return {
            backgroundColor: theme.palette.appComponents.buttonType[careType].backgroundColor,
            strokeColor: theme.palette.appComponents.buttonType[careType].color,
            color: theme.palette.text.primary
        }
    }
    if(children.length === 0) {
        console.log(node, careType, theme.palette.appComponents.buttonType[careType]);
    }

    return theme.palette.appComponents.defaultNode.other;
}

export const generateTree = (data, database, lvl = 0, duplicate = null) => {
    let nextChildren = [];
    const ids = {};

    if(data && data.children && data.children.length > 0) {
        duplicate = duplicate ?? data.duplicate ?? null;
        data.children.filter(child => !ids[child.id]).forEach(child => {
            nextChildren = [
                ...nextChildren,
                generateTree(child, database, lvl + 1, duplicate)
            ];
        });
    }
    nextChildren = nextChildren.sort((a, b) => a.id - b.id);

    return {
        ...data,
        children: nextChildren,
        type: data.children.length === 0 ? data.type : null,
        parentDuplicate: duplicate,
        color: getNodeColor(data, lvl),
        database
    };
}

export const showTree = (node, query = null) => {
    let nextChildren = [];
    const ids = {};
    if(node.children && node.children.length > 0) {
        nextChildren = [...node.children.map(child => {
            const childData = node && node.children
                ? node.children.reduce((accu, item) => item.id === child.id ? item : accu, null)
                : null;
            ids[child.id] = true;
            return showTree(childData, query);
        })];
    }
    nextChildren = nextChildren.sort((a, b) => a.id - b.id);

    return {
        ...node,
        children: nextChildren,
        size: node.children.length === 0 ? isNotFilter(node, query) : 0,
    };
}

