import React from "react";
import PropTypes from "prop-types";

const ErrorLayout = ({children}) => {
    return children;
};

ErrorLayout.propTypes = {
    children: PropTypes.node
};

ErrorLayout.defaultProps = {
    children: null
};

export default ErrorLayout;