export const DISPLAY_ERROR_PAGE = 'DISPLAY_ERROR_PAGE';
export const CLEAR_ERROR_PAGE = 'CLEAR_ERROR_PAGE';

const INITIAL_STATE = {
    current: 0,
    previous: 0,
    code: 404,
    message: null
}

export const hasError = state => {
    return state.current > state.previous;
}

export default function errorPageReducer (state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case DISPLAY_ERROR_PAGE: {
            const {code, message} = payload;

            return {
                ...state,
                current: state.current + 1,
                code: code || 404,
                message: message || null,
            }
        }
        case CLEAR_ERROR_PAGE: {
            return {
                ...state,
                previous: state.current
            }
        }
    }

    return state;
}