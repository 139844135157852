import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useSelector, useDispatch} from "react-redux";
import {CLEAR_ERROR_PAGE, hasError} from "../reducers/errorPageReducer";

const Page = ({children, className}) => {
    const dispatch = useDispatch();
    const isErrorPage = useSelector(state => hasError(state.errorPage));

    useEffect(() => {
        if(isErrorPage) {
            return () => {
                dispatch({type: CLEAR_ERROR_PAGE});
            };
        }

        return () => null;
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.add(className);
        return () => {
            document.body.classList.remove(className);
        };
    }, []);

    return (
        <>
            {children}
        </>
    );
};

Page.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};

Page.defaultProps = {
    children: null,
    className: ''
};

export default Page;