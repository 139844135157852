import { combineReducers } from 'redux';
import errorPageReducer from './errorPageReducer';
import gpsReducer from "./gps/gpsReducer";
import tooltipReducer from "./gps/tooltipReducer";

export default () => {
    const rootReducer = {
        errorPage: errorPageReducer,
        gps: gpsReducer,
        tooltip: tooltipReducer
    };

    return combineReducers(rootReducer);
};