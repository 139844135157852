import {TAG_CATEGORY_DEFAULT} from "../constants/tags";

export class StaticData {
    constructor() {
        if (!StaticData.instance) {
            this.isInitialized = false;
            this.mode = 'priv';
            this.tags = [];
            this.categories = [];
            this.lastUpdate = null;
            StaticData.instance = this;
        }

        return StaticData.instance;
    }

    initialized(data) {
        this.tags = data.tags.sort((a, b) => { return a.order - b.order});
        this.mode = data.mode;
        this.lastUpdate = data.last_update;
        this.categories = [];
        this.tags.forEach(({category}) =>{
            const name = category || 'default';
            const categoryIndex = this.categories.reduce(
                (accu, {name: categoryName}, i) => categoryName === name ? i : accu,
                -1
            );
            if(categoryIndex === -1) {
                this.categories.push({name, count: 1});
            } else {
                this.categories[categoryIndex].count++;
            }
        });
        this.isInitialized = true;
    }

    getTags(category = null) {
        if(!this.isInitialized){
            console.error('StaticData : instance not initialized !');
            return null;
        }
        if(category !== null) {
            return this.tags.filter(tag => tag.category === null && category === TAG_CATEGORY_DEFAULT || tag.category === category);
        }

        return this.tags;
    }

    getCategories() {
        if(!this.isInitialized){
            console.error('StaticData : instance not initialized !');
            return null;
        }

        return this.categories;
    }

    getMode() {
        return this.mode;
    }

    getLastUpdate() {
        return this.lastUpdate;
    }
}

const staticData = new StaticData();
export default staticData;
