const Typography = theme => ({
    body1: {
        fontFamily: "'San Francisco', Helvetica, Arial, sans-serif",
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(24),
        fontWeight: 400
    },
    body2: {
        fontFamily: "'San Francisco', Helvetica, Arial, sans-serif",
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 700
    },
    h1: {
        fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
        fontSize: theme.typography.pxToRem(24),
        lineHeight: theme.typography.pxToRem(32),
        fontWeight: 700
    },
    h2: {
        fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(24),
        fontWeight: 700
    },
    h3: {
        fontFamily: "'San Francisco', Helvetica, Arial, sans-serif",
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 700
    },
    h4: {
        fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
        fontSize: theme.typography.pxToRem(24),
        lineHeight: theme.typography.pxToRem(32),
        fontWeight: 700
    },
    h5: {
        fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
        fontSize: theme.typography.pxToRem(8),
        lineHeight: theme.typography.pxToRem(10),
    },
    subtitle1: {
        fontFamily: "San Francisco, Helvetica, Arial, sans-serif",
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(24),
        letterSpacing: theme.typography.pxToRem(0.13),
        fontWeight: 400,
    },
    caption: {
        fontFamily: "San Francisco, Helvetica, Arial, sans-serif",
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(14),
        fontWeight: 400,
    },
    tag: {
        fontFamily: "'San Francisco', Helvetica, Arial, sans-serif",
        fontSize: theme.typography.pxToRem(10),
        lineHeight: theme.typography.pxToRem(13),
        fontWeight: 700,
    },
    button: {
        fontFamily: "'San Francisco', Helvetica, Arial, sans-serif",
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(14),
        fontWeight: 600
    },
    overrides: {
        gutterBottom: {
            marginBottom: '16px'
        }
    }
});

export default Typography;