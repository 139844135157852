import React, {lazy} from 'react';
import qs from 'qs';
import i18n, {locales, defaultLocale, hasMmultiLanguages} from '../internationalization/i18n';
import {matchPath, Redirect} from 'react-router-dom'
import {
    GPS_PAGE,
    HOME_PAGE, MENTION_PAGE, SLASH_PAGE, THEME_PAGE
} from "../constants/router";

const GpsPage = lazy(() => import('../pages/GpsPage/GpsPage'));
const HomePage = lazy(() => import('../pages/HomePage'));
const ThemePage = lazy(() => import('../pages/ThemePage/ThemePage'));
const MentionsPage = lazy(() => import('../pages/MentionsPage'));
const ErrorPage = lazy(() => import('../pages/ErrorPage'));

export const routesConfig = [
    {name: SLASH_PAGE, component: <HomePage />, isPublic: true},
    {name: GPS_PAGE, component: <GpsPage />, isPublic: true},
    {name: THEME_PAGE, component: <ThemePage />, isPublic: true},
    {name: HOME_PAGE, component: <HomePage />, isPublic: true},
    {name: MENTION_PAGE, component: <MentionsPage />, isPublic: true},
];

const routes = [];

if (hasMmultiLanguages()) {
    routes.push({
        name: null,
        path: "/",
        exact: true,
        localize: false,
        component: () => <Redirect to={`/${defaultLocale}`}/>
    });
}
routesConfig.forEach(({name, component, parent, isPublic}) => {
    Object.keys(locales).forEach(locale => {
        const {routing} = locales[locale];
        const pathPrefix = hasMmultiLanguages()
            ? `/${locale}`
            : ''
        ;
        const localeRouting = routing?.[name];
        if (localeRouting) {
            const path = pathPrefix + localeRouting;
            let parentPath = null;
            const localeParentRouting = routing?.[parent];
            if (localeParentRouting) {
                parentPath = pathPrefix + localeParentRouting;
            }
            routes.push({
                name,
                path,
                exact: true,
                component,
                locale,
                parent: parentPath,
                isPublic
            });
        }
    });
});

routes.push({name:'404', component: <ErrorPage code={404} />});

export const getDefaultRouteName = () => {
    let name = null;
    routes.forEach(route => {
        if (name === null && route.name !== undefined && route.name !== null) {
            name = route.name;
        }
    });

    return name;
};

export const getRouteFromPath = path => {
    let r = null;
    routes.forEach(route => {
        if (route.path === path) {
            r = route;
        }
    });

    return r;
};

export const getRouteFromPageAndLocale = (page, locale) => {
    let r = null;
    routes.forEach(route => {
        const {name, path} = route;
        if (name === page && (!hasMmultiLanguages() || path.indexOf(`/${locale}`) === 0)) {
            r = route;
        }
    });

    return r;
};

export const getParentRoutesFromPage = page => {
    const parentsPath = [];
    const parentRoutes = [];
    routes.forEach(route => {
        const {name, parent} = route;
        if (page === name) {
            parentsPath.push(parent);
        }
    });
    routes.forEach(route => {
        const {path} = route;
        parentsPath.forEach(parentPath => {
            if (parentPath === path) {
                parentRoutes.push(route);
            }
        })
    });

    return parentRoutes;
};

export const getChildRoutesFromPage = page => {
    const childRoutes = [];
    const pageRoutes = [];
    routes.forEach(route => {
        const {name} = route;
        if (page === name) {
            pageRoutes.push(route);
        }
    });
    routes.forEach(route => {
        const {parent} = route;
        pageRoutes.forEach(pageRoute => {
            const {path} = pageRoute;
            if (parent === path) {
                childRoutes.push(route);
            }
        })
    });

    return childRoutes;
};

export const getMatch = pathname => {
    let match = {path: routes[0].path, params: {}};
    for (let i = 0, l = routes.length; i < l; i += 1) {
        const m = matchPath(pathname, routes[i]);
        if (m !== null) {
            match = m;
            break;
        }
    }

    return match;
};

export const is404 = pathname => {
    let notFound = true;
    for (let i = 0, l = routes.length; i < l; i += 1) {
        const m = matchPath(pathname, routes[i]);
        if (m !== null) {
            notFound = false;
            break;
        }
    }

    return notFound;
};

export const getRouteName = pathname => {
    let pageName = '404';
    for (let i = 0, l = routes.length; i < l; i += 1) {
        const m = matchPath(pathname, routes[i]);
        if (m !== null) {
            pageName = routes[i].name;
            break;
        }
    }

    return pageName;
};

export const getQuery = (query, currentQuery = {}) => {
    const nextQuery = {...currentQuery, ...query};
    Object.keys(nextQuery).forEach(queryItemName => {
        if (nextQuery[queryItemName] === null) {
            delete nextQuery[queryItemName];
        }
    });
    let urlQuery = '';
    if (Object.keys(nextQuery).length > 0) {
        urlQuery = qs.stringify(nextQuery, {addQueryPrefix: true});
    }

    return urlQuery;
};

export const getUrlFromPath = (path, params = {}, currentParams = {}) => {
    Object.keys(params).forEach(param => {
        path = path.replace(`:${param}`, params[param]);
    });
    Object.keys(currentParams).forEach(param => {
        path = path.replace(`:${param}`, currentParams[param]);
    });

    if(path.match(/:[^:/]+/)) {
        return false;
    }

    return path;
};

export const getUrl = ({
                           prevPath = null,
                           prevParams = {},
                           prevQuery = {},
                           page = null,
                           params = {},
                           query = {},
                           locale = null,
                           keepParams = true,
                           keepQuery = true
                       }) => {
    const nextLocale = locale === null || locales[locale] === undefined
        ? i18n.language ?? defaultLocale
        : locale
    ;
    const prevRoute = getRouteFromPath(prevPath);
    let nextPage = getDefaultRouteName();
    if (page !== null) {
        nextPage = page;
    } else if (prevRoute !== null) {
        nextPage = prevRoute.name;
    }
    const nextRoute = getRouteFromPageAndLocale(nextPage, nextLocale);
    let url = '/';
    if(nextRoute !== null) {
        url = getUrlFromPath(nextRoute.path, params, keepParams ? prevParams : {});
        if(url === false) {
            console.error(`router getUrl() : You must provide parameters (prevParams or params) for ${nextRoute.path}`)
            url = '/';
        }
    }
    const nextQuery = getQuery(query, keepQuery ? prevQuery : {});

    return url + nextQuery;
};

export default routes;
