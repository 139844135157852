import { hot } from "react-hot-loader/root";
import React from "react";
import PropTypes from "prop-types";
import {BrowserRouter as Router} from 'react-router-dom';
import { Provider } from "react-redux";
import I18nProvider from "./internationalization/I18nProvider";
import {ThemeProvider} from '@material-ui/core/styles';
import theme from './Material/theme/theme'
import CssBaseline from '@material-ui/core/CssBaseline';
import Root from "./Root";

const App = ({store}) => {
    return (
        <Provider store={store}>
            <Router>
                <I18nProvider>
                    <ThemeProvider theme={theme}>
                        <CssBaseline/>
                        <Root/>
                    </ThemeProvider>
                </I18nProvider>
            </Router>
        </Provider>
    )
};

App.propTypes = {
    store: PropTypes.object.isRequired,
};

export default hot(App);