import {darken, alpha} from "@material-ui/core/styles/colorManipulator";
import {createTheme} from "@material-ui/core/styles";
import colors from './colors';
import Typography from "./Typography";
import Checkbox from "./overrides/Checkbox";
import ToggleButtonGroup from "./overrides/ToggleButtonGroup";
import ToggleButton from "./overrides/ToggleButton";
import Button from "./overrides/Button";
import TableCell from "./overrides/TableCell";
import TableRow from "./overrides/TableRow";

const theme = createTheme({
    palette: {
        text: {
            primary: colors.navy,
            secondary:  colors.darkIndigo,
            contrastText: '#fff',
        },
        primary: {
            main: colors.darkIndigo,
            dark: colors.navy
        },
        secondary: {
            main: colors.pinkishOrange,
            dark: colors.reddishOrange
        },
        tableau: {
            backgroundColor: colors.purpleyGrey,
            border: colors.paleGrey
        },
        font: colors.purple,
        success: {
            main: colors.sickGreen,
            dark: darken(colors.sickGreen, 0.1),
        },
        background: {
            default: colors.white
        },
        border: {
            default: colors.border
        },
        grey: {
            50: colors.paleGrey,
            100: colors.purple,
            200: colors.border,
            300: colors.lightGrey,
            400: colors.lightGreyTwo,
            500: colors.lightBlueGrey,
            600: '#8f8baa',
            700: '#5e5a85',
            800: '#2b1d46',
            900: '#000000',
        },
        divider: colors.paleGrey,
        buttonDivider: colors.purpleyGrey,
        appComponents: {
            duplicateNode: {
                backgroundA: alpha(colors.pinkishOrange, 0.2),
                backgroundB: alpha(colors.darkIndigo, 0.2),
            },
            defaultNode: {
                root: {
                    backgroundColor: colors.white,
                    strokeColor: alpha(colors.darkIndigo, 0.8),
                    color: colors.navy
                },
                level1: {
                    backgroundColor: '#f3f2f6',
                    strokeColor: alpha(colors.darkIndigo, 0.8),
                    color: colors.navy,
                },
                other: {
                    backgroundColor: '#f3f2f6',
                    strokeColor: alpha(colors.darkIndigo, 0.2),
                    color: colors.navy,
                    duplicateBackgroundColorA: alpha(colors.pinkishOrange, 0.2),
                    duplicateBackgroundColorB: alpha(colors.darkIndigo, 0.2),
                }
            },
            buttonType: {
                "actes-techniques-medicaux": {
                    color: colors.white,
                    backgroundColor: colors.orange,
                    backgroundColorDark: darken(colors.orange, 0.1),
                },
                biologie: {
                    color: colors.white,
                    backgroundColor: colors.sickGreen,
                    backgroundColorDark: darken(colors.sickGreen, 0.1),
                },
                "prestations-et-consultations": {
                    color: colors.white,
                    backgroundColor: colors.turquoiseBlue,
                    backgroundColorDark: darken(colors.turquoiseBlue, 0.1),
                },
                "dispositifs-medicaux": {
                    color: colors.white,
                    backgroundColor: colors.midBlue,
                    backgroundColorDark: darken(colors.midBlue, 0.1),
                },
                medicaments: {
                    color: colors.white,
                    backgroundColor: colors.redViolet,
                    backgroundColorDark: darken(colors.redViolet, 0.1),
                },
                sejours: {
                    color: colors.white,
                    backgroundColor: colors.goldenRod,
                    backgroundColorDark: darken(colors.goldenRod, 0.1),
                },
            },
            tooltip: {
                borderColor: colors.border,
                backgroundColor: '#fff',
            }
        },
    },
    typography: {
        fontFamily: "'San Francisco', Helvetica, Arial, sans-serif",
        htmlFontSize: 16,
        fontWeight: 400,
    },
    props: {
        MuiButton: {
            disableElevation: true
        }
    }
});

theme.typography = {
    ...theme.typography,
    ...Typography(theme)
};
theme.overrides = {
    MuiButton: Button(theme),
    MuiCheckbox: Checkbox(theme),
    MuiToggleButton: ToggleButton(theme),
    MuiToggleButtonGroup: ToggleButtonGroup(theme),
    MuiTableCell: TableCell(theme),
    MuiTableRow: TableRow(theme),
};

export default theme;
