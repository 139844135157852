import {ACTION_UPDATE_FILTER} from "../../constants/gps";
import staticData from "../../manager/staticData";
import {TAG_CATEGORY_ANNEE, TAG_CATEGORY_BDD, TAG_CATEGORY_MISC, TAG_CATEGORY_SOIN} from "../../constants/tags";
import {getTagsCount} from "../../manager/gps";
import {getFullQuery} from "./gpsReducer";

export const INITIAL_STATE = {
    initialized: false,
    pending: false,
    fulfilled: false,
    error: false,
    tagsCount: {},
    care: [],
    year: [],
    misc: []
};

export const compareQuery = (query1, query2) => {
    if(query1 === null && query2 === null) return true;
    if(query1 === null && query2 !== null) return false;
    if(query1 !== null && query2 === null) return false;
    if(query1.length !== query2.length) return false;

    let same = true;
    for(let i = 0, l1 = query1.length; i < l1; i++) {
        let find = false;
        const value1 = `${query1[i]}`;
        for(let j = 0, l2 = query2.length; j < l2; j++) {
            const value2 = `${query2[j]}`;
            if(value1 === value2) find = true;
        }
        if(!find) {
            same = false;
            break;
        }
    }

    return same;
};

export const findbase = (query) => {
    let base = 1;
    staticData.getTags(TAG_CATEGORY_BDD).forEach((tag) => {
        query.forEach((param) => {
            if(parseInt(param, 10) === tag.id) {
                base = parseInt(param, 10);
            }
        })
    })

    return base;
}

export const findCatInQuery = (query, category) => {
    const staticIds = staticData.getTags(category).map((cat) => cat.id);
    return query.filter((param) => staticIds.includes(parseInt(param, 10))).map(Number);
}

export default function filterReducer (state = INITIAL_STATE, action) {
    const {type, payload} = action;

    switch(type) {
        case ACTION_UPDATE_FILTER : {
            const {query, root} = payload;
            const {year: yearState, misc: miscState, care: careState} = state;
            const year = findCatInQuery(query, TAG_CATEGORY_ANNEE);
            const misc = findCatInQuery(query, TAG_CATEGORY_MISC);
            const care = findCatInQuery(query, TAG_CATEGORY_SOIN);
            const fullQuery = getFullQuery(query);

            if (year === yearState && misc === miscState && care === careState) return state;

            return {
                ...state,
                pending: false,
                fulfilled: true,
                year,
                misc,
                care,
                tagsCount: getTagsCount(root, {}, fullQuery)
            }
        }
    }

    return state;
}