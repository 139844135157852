import {ACTION_TOOLTIP_CLOSE, ACTION_TOOLTIP_TOGGLE} from "../../constants/tooltip";
import staticData from "../../manager/staticData";
import {TAG_CATEGORY_SOIN} from "../../constants/tags";

const INITIAL_STATE = {
    id: null,
    label: null,
    care: null,
    duplicate: null,
    description: null,
    descriptionPrivate: null,
    top: 0,
    left: 0,
    direction: 'down',
    open: false
}

export default function tooltipReducer (state = INITIAL_STATE, action) {
    const {type, payload} = action;

    switch(type) {
        case ACTION_TOOLTIP_TOGGLE: {
            const {id, label, description, top, left, direction, descriptionPrivate, care, duplicate} = payload;
            const tags = staticData.getTags(TAG_CATEGORY_SOIN);
            if(state.open && state.id === id) {
                return {
                    ...state,
                    open: false
                };
            }
            return {
                ...state,
                id,
                label,
                care: care ? tags.find(tag => tag.slugify_label === care) : null,
                duplicate: duplicate !== 'nan' ? duplicate : null,
                description,
                descriptionPrivate,
                top,
                left,
                direction,
                open: true
            };
        }
        case ACTION_TOOLTIP_CLOSE: {
            return {
                ...state,
                open: false
            };
        }
    }

    return state;
}