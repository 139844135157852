const TableCell = theme => ({
    root: {
        padding: '8px',
        borderBottom: `solid ${theme.palette.tableau.border} 1px`,
        '&:first-child': {
            paddingLeft: '16px',
        },
        '&:last-child': {
            paddingRight: '16px',
        }
    },
    head: {
        color : '#fff',
        backgroundColor: theme.palette.tableau.backgroundColor,
    },
    stickyHeader: {
        color : '#fff',
        backgroundColor: theme.palette.tableau.backgroundColor,
    },
});

export default TableCell;