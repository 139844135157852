import React from "react";
import PropTypes from "prop-types";
import Page from "./Page";
import useRouter from "../hooks/router/useRouter";
import {SLASH_PAGE} from "../constants/router";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

import img500 from "../assets/img/Image-500@1x.png";
import img5002x from "../assets/img/Image-500@2x.png";
import img404 from "../assets/img/Image-404@1x.png";
import img4042x from "../assets/img/Image-404@2x.png";
import img403 from "../assets/img/Image-403.png";
import img4032x from "../assets/img/Image-403@2x.png";

const useStyles = makeStyles(theme => ({
    ErrorPageRoot: {
        marginTop: '100px',
        '& .title': {
            fontSize: theme.typography.pxToRem(30),
            letterSpacing: '-0.21px',
            color: theme.palette.grey[700],
            textAlign: 'center',
            marginBottom: '24px',
        },
        '& .message': {
            fontWeight: 400,
            textAlign: 'center',
            '& a': {
                textDecoration: 'none',
                color: '#FF6900',
                '&:hover': {
                    textDecoration: 'underline',
                }
            }
        },
        '& .image': {
            display: 'block',
            margin: 'auto'
        }
    }
}));

const ErrorPage = ({code, message}) => {
    const {t} = useTranslation();
    const getUrl = useRouter();
    const classes = useStyles();

    const backUrl = getUrl({page: SLASH_PAGE});
    const imgSrc = {
        500: {
            'src': img500,
            'srcset': img5002x
        },
        404: {
            'src': img404,
            'srcset': img4042x
        },
        403: {
            'src': img403,
            'srcset': img4032x
        },
        401: {
            'src': img403,
            'srcset': img4032x
        }
    }

    return (
        <Page className="error">
            <div className={classes.ErrorPageRoot}>
                <Typography className="title" variant="h1">
                    {t(`erreur.${code}.title`)}
                </Typography>
                <Typography className="message" variant="h3" component="p">
                    <span dangerouslySetInnerHTML={{
                        __html: t(`erreur.message`, {link: `<a href="${backUrl}">${t('erreur.link')}</a>`})
                    }} />
                </Typography>
                <img className="image" alt="" src={imgSrc[code].src} srcSet={`${imgSrc[code].srcset} 2x`} />
            </div>
        </Page>
    );
};

ErrorPage.propTypes = {
    code: PropTypes.number,
    message: PropTypes.string
};

ErrorPage.defaultProps = {
    code: 404,
    message: null
};

export default ErrorPage;