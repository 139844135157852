import {
    ACTION_GPS_FETCH_ERROR,
    ACTION_GPS_FETCH_FULFILLED,
    ACTION_GPS_FETCH_PENDING,
    ACTION_UPDATE_FILTER
} from "../../constants/gps";
import {generateTree, showTree} from "../../manager/gps";
import filterReducer, {
    findCatInQuery,
    INITIAL_STATE as FILTER_INITIAL_STATE
} from "./filterReducer";
import {TAG_CATEGORY_ANNEE, TAG_CATEGORY_MISC, TAG_CATEGORY_SOIN} from "../../constants/tags";


const INITIAL_STATE = {
    initialized: false,
    pending: false,
    fulfilled: false,
    error: false,
    query: [],
    root: null,
    refreshTreeCount: 0,
    database: null,
    display: 'roue',
    filter: FILTER_INITIAL_STATE
};

export const getFullQuery = (query) => {
    return {
        care: findCatInQuery(query, TAG_CATEGORY_SOIN),
        misc: findCatInQuery(query, TAG_CATEGORY_MISC),
        year: findCatInQuery(query, TAG_CATEGORY_ANNEE)
    };
};

export default function gpsReducer (state = INITIAL_STATE, action) {
    const {type, payload} = action;

    switch(type) {
        case ACTION_GPS_FETCH_PENDING : {
            const {database} = payload;
            return {
                ...state,
                database,
                initialized: true,
                pending: true,
            }
        }
        case ACTION_GPS_FETCH_FULFILLED : {
            const {database, display, data} = payload;
            const {database: prevBase, display: prevDisplay} = state.filter;
            if (database === prevBase && display === prevDisplay) return state;

            const root = generateTree(data, database);
            return {
                ...state,
                refreshTreeCount: 0,
                pending: false,
                fulfilled: true,
                database,
                root,
            }
        }
        case ACTION_GPS_FETCH_ERROR : {
            const {base} = payload;
            const {base: prevBase} = state;
            if(base !== prevBase) return state;

            return {
                ...state,
                pending: false,
                error: true
            }
        }
        case ACTION_UPDATE_FILTER : {
            const {query} = payload;
            const {root: prevRoot, refreshTreeCount} = state;
            const fullQuery = getFullQuery(query);

            const root = showTree(prevRoot, fullQuery);

            return {
                ...state,
                query,
                root,
                pending: false,
                fulfilled: true,
                refreshTreeCount: refreshTreeCount + 1,
                filter: filterReducer(state.filter,
                    {
                        type: ACTION_UPDATE_FILTER,
                        payload: {
                            query,
                            root
                        }
                    })
            }
        }
    }

    return state;
}