import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import {useTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import sizes from "../Material/theme/sizes"

const useStyles = makeStyles(theme => ({
    LoaderRoot: {
        marginTop: '36px',
        marginLeft: `${sizes.leftPanelWidth + 29}px`,
    }
}));

const Loader = ({textKey}) => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <Typography className={cn(classes.LoaderRoot, textKey)} variant="h3" component="p">
            {t(`loader.${textKey}`)}
        </Typography>
    );
};

Loader.propTypes = {
    textKey: PropTypes.string.isRequired
};

Loader.defaultProps = {

};

export default Loader;