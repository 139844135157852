import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {hasError} from "../reducers/errorPageReducer";
import ErrorLayout from "../layouts/ErrorLayout/ErrorLayout";
import ErrorPage from "../pages/ErrorPage";
import Layout from "../layouts/Layout";

const Route = ({name, component, isPublic}) => {
    const isErrorPage = useSelector(state => hasError(state.errorPage));
    const errorCode = useSelector(state => state.errorPage.code);
    const errorMessage = useSelector(state => state.errorPage.message);

    if(isErrorPage) {
        return (
            <ErrorLayout error={errorCode}>
                <ErrorPage code={errorCode} message={errorMessage} />
            </ErrorLayout>
        );

    }

    return (
        <Layout>
            {component}
        </Layout>
    );
};

Route.propTypes = {
    name: PropTypes.string,
    component: PropTypes.node,
    isPublic: PropTypes.bool

};

Route.defaultProps = {
    name: null,
    component: null,
    isPublic: true

};

export default Route;